<template>
  <ion-grid class="statistica" :class="{ '  padding-top': showPaddingTop }">
    <ion-row v-if="showMaxMin" class="ion-justify-content-between">
      <ion-col size="auto ">
        <span class="small-titles">{{ min }}</span>
      </ion-col>

      <ion-col size="auto ">
        <span class="small-titles"> {{ max }}</span>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col :class="{ animateProgressBar: animate }">
        <ion-progress-bar
          class="fat"
          :style="{ '--progress-background': color }"
          :value="valore"
        ></ion-progress-bar>
        <ion-col class="valore" size="auto" :style="scorePosition">
          {{ valore }}
        </ion-col>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
import { IonProgressBar, IonRow, IonCol, IonGrid } from "@ionic/vue";

export default {
  name: "VTStatistica",
  props: {
    min: String,
    max: String,
    valore: Number,
    color: String,
    showPaddingTop: {
      type: Boolean,
      default: true,
    },
    showMaxMin: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    IonProgressBar,
    IonRow,
    IonCol,
    IonGrid,
  },
  data() {
    return {
      finalValue: 1,
      progress: 0,
      animate: true,
    };
  },
  created() {
    this.finalvalue = this.valore / 10;
    const that = this;
    setTimeout(() => {
      // that.progress = Math.min(1,this.value+0.1);
      that.progress = 0;
    }, 500);
    setTimeout(() => {
      that.progress = this.valore / 10;
    }, 1000);
  },
  methods: {},
  computed: {
    scorePosition() {
      return {
        "--left": this.progress * 100 + "%",
        background: this.color,
      };
    },
  },
};
</script>

<style scoped>
.animateProgressBar ion-progress-bar::part(progress) {
  transform: scaleX(v-bind("progress"));
  /*transition: transform 0.5s linear 0s, -webkit-transform 0.5s linear 0s;*/
  transition: 3s cubic-bezier(0.2, 0.5, 0.1, 1);
}

.animateProgressBar .valore {
  transform: translateX(0vw);
  transition: 3s cubic-bezier(0.2, 0.5, 0.1, 1);
}

.statistica {
  position: relative;
  padding: 0.6rem 0 0 0;
}

/* .padding-top {
  padding-top: 1rem;
} */

.valore {
  transform: translateX(-100vw);
  text-align: center;
  min-width: 30px;
  padding: 0.35rem;
  position: absolute;
  top: -2px;
  left: calc(var(--left) - 15px);
  border-radius: 100%;
  color: #ffffff;
  background: var(--ion-color-secondary);
  font-size: 0.9rem;
  font-weight: bold;
  z-index: 100;
}

ion-progress-bar {
  --background: #e6e6e6;
  box-shadow: inset 0 0 4px 1px #bbb;
  height: 15px;
}

.small-titles {
  font-size: 0.75rem;
  font-weight: lighter;
}
</style>