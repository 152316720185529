<template>
  <ion-grid class="categoria">
    <ion-row class="ion-justify-content-center categoria-titolo">
      <ion-col size="auto">
        <p>{{ nome.toUpperCase() }}</p>
      </ion-col>
    </ion-row>
    <ion-row>
      <transition-group name="block-rise-in" tag="ion-col">
        <template v-for="statistica in statistiche" :key="statistica.ID">
          <VTStatistica
            :min="statistica.LabelSx"
            :max="statistica.LabelDx"
            :valore="Math.round(statistica.Valore)"
            :color="statistica.color"
            :style="{ '--i': statistica.enterOrder }"
            v-if="showList1 && showList2"
          ></VTStatistica>
        </template>
      </transition-group>
    </ion-row>
  </ion-grid>
</template>

<script>
import VTStatistica from "./VTStatistica.vue";
import { hslToHex } from "../utils/hslToRgb";
import { hexToHSL } from "../utils/hexToHsl";
import { IonRow, IonCol, IonGrid } from "@ionic/vue";

export default {
  name: "ValutazioneTalenti",
  components: {
    IonRow,
    IonCol,
    IonGrid,
    VTStatistica,
  },
  props: {
    nome: String,
    statistiche: Object,
  },
  created() {
    setTimeout(() => {
      this.$nextTick(() => {
        this.showList1 = true;
      });
    }, 1);

    const hexPrimaryColor = this.whappyClientConfig.primaryColor;
    const hexSecondaryColor = this.whappyClientConfig.secondaryColor;

    const hslPrimaryColor = hexToHSL(hexPrimaryColor);
    const hslSecondaryColor = hexToHSL(hexSecondaryColor);

    let hue = hslPrimaryColor.h;
    let range =
      (hslPrimaryColor.h - hslSecondaryColor.h) / this.statistiche.length;
    // let hue = 200;
    let saturation = 80;
    let lightness = 60;

    // let range = 140 / this.statistiche.length;

    let counter = 0;

    this.statistiche.forEach((element) => {
      counter++;
      element.enterOrder = counter;
      element.color = hslToHex(hue, saturation, lightness);
      if (hue + range < 360) {
        hue += range;
      } else {
        hue += range - 360;
      }
    });
  },
  mounted() {
    setTimeout(() => {
      this.$nextTick(() => {
        this.showList2 = true;
      });
    }, 1);
  },
  data() {
    return {
      showList1: false,
      showList2: false,
    };
  },
};
</script>


<style scoped>
* {
  font-size: 0.7rem;
}

.categoria {
  padding-top: 1rem;
}

.categoria-titolo {
  padding-top: 1rem;
  text-align: center;
}

.categoria-titolo p {
  color: #5f5f5f;
  margin: 0;
  font-size: 1rem;
}
</style>